export const SET_DATE_RANGE = "set/daterange";
export const DATE_RANGE = "daterange";

const state = {
  dateRange: {
    startDate: new Date().setDate(new Date().getDate() - 7),
    endDate: new Date()
  }
};

const getters = {
  dateRange(state) {
    return state.dateRange;
  }
};

const actions = {
  [SET_DATE_RANGE](context, dateRange) {
    context.commit(DATE_RANGE, dateRange);
  }
};

const mutations = {
  [DATE_RANGE](state, dateRange) {
    state.dateRange = dateRange;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
