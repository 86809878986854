import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

export const LOAD_SEARCH_HISTORY = "[get]stats/search_history";
export const LOAD_TRENDING_SEARCHES = "[get]stats/trending_searches";
export const LOAD_TRENDING_ITEMS = "[get]stats/trending_items";
export const LOAD_SEARCH_HISTORY_ITEM = "[get]stats/search_history_item";
export const LOAD_SEARCH_HISTORY_ITEM_ITEM =
  "[get]stats/search_history_item_item";

export const LOAD_SEARCHES_WITHOUT_RESULTS =
  "[get]stats/searches_without_results";
export const SEARCH_HISTORY = "search_history";
export const TRENDING_SEARCHES = "trending_searches";
export const TRENDING_ITEMS = "trending_items";
export const SEARCHES_WITHOUT_RESULTS = "searches_without_results";
export const SEARCH_HISTORY_ITEM = "search_history_item";
export const SEARCH_ITEM_HISTORY_ITEM = "search_item_history_item";

const state = {
  top_queries: [],
  top_no_results: [],
  total_queries: 0,
  total_no_results: 0,
  took_time: 0,
  queries_timeline: [],
  tables: {
    trending_searches: [
      {
        timeline: [
          {
            data: []
          }
        ],
        queries: 0
      }
    ],
    searches_without_results: [
      {
        timeline: [
          {
            data: [
              { x: "09/23/2021 GMT", y: "157" },
              {
                x: "09/24/2021 GMT",
                y: "96"
              }
            ]
          }
        ],
        queries: 0,
        users_affected: 0
      }
    ],
    search_history_item: [
      {
        timeline: [
          {
            data: [
              { x: "09/23/2021 GMT", y: "157" },
              {
                x: "09/24/2021 GMT",
                y: "96"
              }
            ]
          }
        ],
        queries: 0
      }
    ],
    search_item_history_item: [
      {
        timeline: [
          {
            data: [
              { x: "09/23/2021 GMT", y: "157" },
              {
                x: "09/24/2021 GMT",
                y: "96"
              }
            ]
          }
        ],
        queries: 0
      }
    ],
    search_items: [
      {
        timeline: [
          {
            data: [
              { x: "09/23/2021 GMT", y: "157" },
              {
                x: "09/24/2021 GMT",
                y: "96"
              }
            ]
          }
        ],
        queries: 0
      }
    ],
    trending_items: []
  },
  loading: false
};

const actions = {
  [LOAD_SEARCH_HISTORY](context, data) {
    state.loading = true;
    return SecureApiService.get("widget/search_history", "?" + data)
      .then(({ data }) => {
        context.commit(SEARCH_HISTORY, data.data);
        state.loading = false;
      })
      .catch(({ response }) => {
        context.commit(SET_MESSAGE, response);
        state.loading = false;
      });
  },
  [LOAD_TRENDING_SEARCHES](context, data) {
    state.loading = true;
    return SecureApiService.get("widget/trending-searches", "?" + data)
      .then(({ data }) => {
        context.commit(TRENDING_SEARCHES, data.data);
        state.loading = false;
      })
      .catch(({ response }) => {
        context.commit(SET_MESSAGE, response);
        state.loading = false;
      });
  },
  [LOAD_TRENDING_ITEMS](context, data) {
    state.loading = true;
    return SecureApiService.get("widget/trending-items", "?" + data)
      .then(({ data }) => {
        context.commit(TRENDING_ITEMS, data.data);
        state.loading = false;
      })
      .catch(({ response }) => {
        context.commit(SET_MESSAGE, response);
        state.loading = false;
      });
  },
  [LOAD_SEARCHES_WITHOUT_RESULTS](context, data) {
    state.loading = true;
    return SecureApiService.get("widget/searches_without_results", "?" + data)
      .then(({ data }) => {
        context.commit(SEARCHES_WITHOUT_RESULTS, data.data);
        state.loading = false;
      })
      .catch(({ response }) => {
        context.commit(SET_MESSAGE, response);
        state.loading = false;
      });
  },
  [LOAD_SEARCH_HISTORY_ITEM](context, data) {
    state.loading = true;
    return new Promise(resolve => {
      return SecureApiService.get(
        "widget/search_history_item",
        "?" + data.join("&")
      )
        .then(({ data }) => {
          context.commit(SEARCH_HISTORY_ITEM, data.data);
          state.loading = false;
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_MESSAGE, response);
          state.loading = false;
        });
    });
  },
  [LOAD_SEARCH_HISTORY_ITEM_ITEM](context, data) {
    state.loading = true;
    return SecureApiService.get(
      "widget/search_item_history_item",
      "?" + data.join("&")
    )
      .then(({ data }) => {
        context.commit(SEARCH_ITEM_HISTORY_ITEM, data.data);
        state.loading = false;
      })
      .catch(({ response }) => {
        context.commit(SET_MESSAGE, response);
        state.loading = false;
      });
  }
};

const mutations = {
  [SEARCH_HISTORY](state, data) {
    state.top_queries = data.top_queries;
    state.top_no_results = data.top_no_results;

    state.took_time = data.took_time;

    state.total_queries = data.total_queries;
    state.total_no_results = data.total_no_results;
  },
  [TRENDING_SEARCHES](state, data) {
    state.tables.trending_searches = data;
  },
  [TRENDING_ITEMS](state, data) {
    state.tables.trending_items = data;
  },
  [SEARCHES_WITHOUT_RESULTS](state, data) {
    state.tables.searches_without_results = data;
  },
  [SEARCH_HISTORY_ITEM](state, data) {
    state.tables.search_history_item = data;
  },
  [SEARCH_ITEM_HISTORY_ITEM](state, data) {
    state.tables.search_item_history_item = data;
  }
};

export default {
  state,
  actions,
  mutations
};
