import ApiService from "@/core/services/api.service";

export const SEARCH_MODULE = "[get]search_module";

export const SEARCH_RESPONSE = "response";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  items: [],
  results: [],
  took: 0,
  total_count: 0,
  total_pages: 0
};
const getters = {};

const actions = {
  [SEARCH_MODULE](context, data) {
    return ApiService.get(
      "/search/",
      "?debug=1&q=" + data.q + "&c=" + data.c
    ).then(({ data }) => {
      context.commit(SEARCH_RESPONSE, data);
    });
  }
};

const mutations = {
  [SEARCH_RESPONSE](state, data) {
    if (data.data === undefined) {
      state.items = [];
      state.took = 0;
      state.total_count = 0;
      state.total_pages = 0;
    } else {
      state.items = data.data.items;
      state.took = data.took;
      state.total_count = data.data.total_count;
      state.total_pages = data.data.total_pages;
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
