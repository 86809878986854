import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

export const PULL_SCHEMA_AUTOCOMPLETE = "[pull]/schema/$id/autocomplete";
export const DATA = "data";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  items: []
};

const actions = {
  [PULL_SCHEMA_AUTOCOMPLETE](context, schema) {
    state.loading = true;
    return SecureApiService.get(
      "schema/" +
        schema.collection_id +
        "/" +
        schema.field_name +
        "/autocomplete"
    )
      .then(({ data }) => {
        state.loading = false;
        context.commit(DATA, data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.err) {
          context.commit(SET_MESSAGE, response.err);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: "Internal error, please try again a few minutes later."
          });
        }
      });
  }
};

const mutations = {
  [DATA](state, data) {
    state.loading = false;
    state.items = data.data;
  }
};

export default {
  state,
  actions,
  mutations
};
