import ApiService from "@/core/services/api.service";

// action types
export const TRY_SEARCH_QUERY = "query";
export const TRY_SEARCH_AUTOCOMPLETE = "autocomplete";

// mutation types
export const TRY_SEARCH_SET_ITEMS = "setFilteredItems";
export const TRY_SEARCH_ITEMS = "filteredItems";

export const REFRESH_TRY_SEARCH = "refreshTrySearch";

const state = {
  filteredItems: []
};
const getters = {};

const actions = {
  [TRY_SEARCH_QUERY](context, data) {
    return ApiService.get("autocomplete?" + data).then(({ data }) => {
      context.commit(TRY_SEARCH_SET_ITEMS, data.data);
    });
  },
  [TRY_SEARCH_AUTOCOMPLETE](context, data) {
    return ApiService.get("autocomplete?" + data).then(({ data }) => {
      context.commit(TRY_SEARCH_SET_ITEMS, data.data);
    });
  }
};

const mutations = {
  [TRY_SEARCH_SET_ITEMS](state, data) {
    state.filteredItems = data;
  },
  [REFRESH_TRY_SEARCH](state) {
    state.filteredItems = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
