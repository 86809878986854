import SecureApiService from "@/core/services/secureapi.service";

export const GET_SHOPTET = "get/shoptet_info";
export const TOGGLE_SHOPTET_LIVE = "toggle/live_mode";
export const SHOPTET_DATA = "shoptet_data";

const state = {
  is_live: false,
  website: ""
};

const actions = {
  [GET_SHOPTET](context) {
    state.loading = true;
    return SecureApiService.get("shoptet_info")
      .then(({ data }) => {
        state.loading = false;
        context.commit(SHOPTET_DATA, data.data);
      })
      .catch(() => {
        state.loading = false;
      });
  },
  [TOGGLE_SHOPTET_LIVE]() {
    state.loading = true;
    return new Promise(resolve => {
      return SecureApiService.get("toggle/live_mode")
        .then(({ data }) => {
          state.loading = false;
          resolve(data.data);
        })
        .catch(({ data }) => {
          state.loading = false;
          resolve(data.data);
        });
    });
  }
};

const mutations = {
  [SHOPTET_DATA](state, data) {
    state.loading = false;
    state.website = data.website;
    state.is_live = data.is_live;
  }
};

export default {
  state,
  actions,
  mutations
};
