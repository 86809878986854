import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const PULL_PERSONAL_INFO = "user/data";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
  user_info: {
    photo: "",
    name: "James",
    surname: "Jones",
    company_name: "Fifestudios",
    job: "Application Developer",
    email: "matt@fifestudios.com",
    phone: "44(76)34254578",
    company_site: "fifestudios",
    is_admin: false,
    is_owner: false,
    user_groups: []
  },
  loading: false
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_info;
  },

  currentUserAccountInfo(state) {
    return state.user_info;
  },

  currentUserPhoto(state) {
    return state.user_info.photo;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    state.loading = true;
    return ApiService.put("user/data", payload).then(({ data }) => {
      state.loading = false;
      context.commit(SET_PERSONAL_INFO, data.data);
    });
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [PULL_PERSONAL_INFO](context) {
    return ApiService.get("user/data").then(({ data }) => {
      context.commit(SET_PERSONAL_INFO, data.data);
    });
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
