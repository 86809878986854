export const SET_MESSAGE = "setmessage";
export const RELOAD_MESSAGE = "reloadmessage";
export const RELOAD_MESSAGE_MUT = "reloadmessagemut";

const state = {
  message: {
    text: "",
    color: "",
    show: false
  }
};

export class Message {
  constructor(text, color, show) {
    this.text = text;
    this.color = color;
    this.show = show;
  }
}

const actions = {
  [RELOAD_MESSAGE](context) {
    context.commit(RELOAD_MESSAGE_MUT);
  }
};

const mutations = {
  [SET_MESSAGE](state, data) {
    state.message = {
      text: data.msg,
      color: data.err === 1 ? "danger" : "success",
      show: true
    };
  },
  [RELOAD_MESSAGE_MUT](state) {
    state.message = {
      text: "",
      color: "",
      show: false
    };
  }
};

export default {
  state,
  mutations,
  actions
};
