import ApiService from "@/core/services/api.service";

// action types
export const PING_ELASTIC_INDEX = "elastic/ping";
export const PULL_ELASTIC_COUNT_OF_DOC = "elastic/count";
export const RELOAD_ELASTIC = "elastic/reload";

// mutation types
export const SET_ELASTIC_LIVE_STATE = "setElasticLive";
export const SET_ELASTIC_COUNT = "setElasticCount";

const state = {
  isElasticLive: null,
  countOfDoc: false,
  loading: false
};

const getters = {
  isElasticLive(state) {
    return state.isElasticLive;
  },
  countOfDoc(state) {
    return state.countOfDoc;
  }
};

const actions = {
  [RELOAD_ELASTIC]() {
    state.loading = true;
    return ApiService.get("elastic/reload").then(() => {
      state.loading = false;
    });
  },
  [PING_ELASTIC_INDEX](context) {
    return ApiService.get("elastic/ping")
      .then(({ data }) => {
        context.commit(SET_ELASTIC_LIVE_STATE, data.err === 0);
      })
      .catch(() => {
        context.commit(SET_ELASTIC_LIVE_STATE, false);
      });
  },
  [PULL_ELASTIC_COUNT_OF_DOC](context) {
    return ApiService.get("elastic/count")
      .then(({ data }) => {
        context.commit(SET_ELASTIC_COUNT, data.data.count);
      })
      .catch(() => {
        context.commit(SET_ELASTIC_LIVE_STATE, false);
      });
  }
};

const mutations = {
  [SET_ELASTIC_LIVE_STATE](state, statement) {
    state.isElasticLive = statement;
  },
  [SET_ELASTIC_COUNT](state, count) {
    state.countOfDoc = count;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
