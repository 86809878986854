import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";

Vue.use(Router);
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
const router = new Router({
  // ...
});

Sentry.init({
  Vue,
  dsn: "https://b8ec67486a7f48a0a6b3be9c34ac88ee@sentry.praguebest.cz/15",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my.upsearch.pb", "my.upsearch.cz", "my.upsearch.praguebest.cz", /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/analytics",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (
              store.state.profile.user_info.is_admin ||
              store.state.profile.user_info.is_owner ||
              store.state.profile.user_info.user_groups.some(
                item => item.id == 1
              )
            ) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/trending-searches",
              name: "trending-searches",
              component: () => import("@/view/pages/trending-searches/list.vue")
            },
            {
              path: "/trending-searches/item",
              name: "trending-searches-item",
              component: () => import("@/view/pages/trending-searches/item.vue")
            },
            {
              path: "/searches-without-results",
              name: "searches-without-results",
              component: () =>
                import("@/view/pages/searches-without-results/list.vue")
            },
            {
              path: "/trending-items",
              name: "trending-items",
              component: () => import("@/view/pages/trending-items/list.vue")
            },
            {
              path: "/trending-item",
              name: "trending-item",
              component: () => import("@/view/pages/trending-items/item.vue")
            },
            {
              path: "/history-query/autocomplete",
              name: "history-query-autocomplete",
              component: () =>
                import("@/view/pages/history-query/autocomplete.vue")
            },
            {
              path: "/history-query/search",
              name: "history-query-search",
              component: () => import("@/view/pages/history-query/search.vue")
            }
          ]
        },
        {
          path: "/search-settings",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (
              store.state.profile.user_info.is_admin ||
              store.state.profile.user_info.is_owner ||
              store.state.profile.user_info.user_groups.some(
                item => item.id == 2
              )
            ) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/check-items",
              name: "check-items",
              component: () => import("@/view/pages/check-items/index.vue")
            },
            {
              path: "/chars-filter",
              name: "chars-filter",
              component: () => import("@/view/pages/chars-filter/list.vue")
            },
            {
              path: "/fixit",
              name: "fixit",
              component: () => import("@/view/pages/fixit/list.vue")
            },
            {
              path: "/synonyms",
              name: "synonyms",
              component: () => import("@/view/pages/synonyms/Synonyms.vue")
            },
            {
              path: "/stopwords",
              name: "stopwords",
              component: () => import("@/view/pages/stopwords/list.vue")
            },
            {
              path: "/curations",
              name: "curations",
              component: () => import("@/view/pages/curations/list.vue")
            },
            {
              path: "/curations/edit",
              name: "curation-edit",
              component: () => import("@/view/pages/curations/edit.vue")
            },
            {
              path: "/relevance-tuning",
              name: "relevance-tuning",
              component: () => import("@/view/pages/relevance-tuning/edit.vue")
            }
          ]
        },
        {
          path: "/data",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (
              store.state.profile.user_info.is_admin ||
              store.state.profile.user_info.is_owner ||
              store.state.profile.user_info.user_groups.some(
                item => item.id == 3
              )
            ) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/import",
              name: "import",
              component: () => import("@/view/pages/import/list.vue")
            },
            {
              path: "/import/edit",
              name: "import-edit",
              component: () => import("@/view/pages/import/ImportEdit.vue")
            },
            {
              path: "/import/error-data",
              name: "import-error-data",
              component: () => import("@/view/pages/import/error_data.vue")
            },
            {
              path: "/collections",
              name: "collections",
              component: () => import("@/view/pages/collections/list.vue")
            },
            {
              path: "/phrases",
              name: "phrases",
              component: () => import("@/view/pages/phrases/list.vue")
            },
            {
              path: "/schema",
              name: "schema",
              component: () => import("@/view/pages/schema/list.vue")
            }
          ]
        },
        {
          path: "/developer",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (
              store.state.profile.user_info.is_admin ||
              store.state.profile.user_info.is_owner ||
              store.state.profile.user_info.user_groups.some(
                item => item.id == 4
              )
            ) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/credentials",
              name: "credentials",
              component: () => import("@/view/pages/credentials/list.vue")
            },
            {
              path: "/compare-results",
              name: "compare-results",
              component: () => import("@/view/pages/compare-results/index.vue")
            },
            {
              path: "/analyze",
              name: "analyze",
              component: () => import("@/view/pages/analyze/index.vue")
            },
            {
              path: "/result-settings",
              name: "result-settings",
              component: () => import("@/view/pages/result-settings/edit.vue")
            },
            {
              path: "/result-settings/catalog",
              name: "result-settings-catalog",
              component: () => import("@/view/pages/result-settings/catalog.vue")
            }
          ]
        },
        {
          path: "/access-management",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (
              store.state.profile.user_info.is_admin ||
              store.state.profile.user_info.is_owner ||
              store.state.profile.user_info.user_groups.some(
                item => item.id == 5
              )
            ) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/users",
              name: "users",
              component: () => import("@/view/pages/users/list.vue")
            }
          ]
        },
        {
          path: "/super-admin",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (store.state.profile.user_info.is_admin) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/all-users",
              name: "all-users",
              component: () => import("@/view/pages/users/all.vue")
            },
            {
              path: "/api-logs",
              name: "api-logs",
              component: () => import("@/view/pages/api-logs/index.vue")
            }
          ]
        },
        {
          path: "/owner",
          component: () => import("@/view/layout/Wrapper"),
          props: true,
          // Route Guard
          beforeEnter: (to, from, next) => {
            if (
              store.state.profile.user_info.is_admin ||
              store.state.profile.user_info.is_owner
            ) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
          children: [
            {
              path: "/projects",
              name: "project-list",
              component: () => import("@/view/pages/project/list.vue")
            },
            {
              path: "/project",
              name: "project-edit",
              component: () => import("@/view/pages/project/edit.vue")
            }
          ]
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/query-tester",
          name: "query-tester",
          component: () => import("@/view/pages/query-tester/index.vue")
        },
        {
          path: "/documents",
          name: "documents",
          component: () => import("@/view/pages/documents/Documents.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile-1.vue")
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },

    {
      path: "/",
      component: () => import("@/view/pages/authentication/Auth"),
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("@/view/pages/authentication/SignIn")
        },
        {
          path: "/register",
          name: "register",
          component: () => import("@/view/pages/authentication/SignUp")
        },
        {
          path: "/password-reset",
          name: "password-reset",
          component: () => import("@/view/pages/authentication/PasswordReset")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
