// action types
import SecureApiService from "../secureapi.service";

export const PULL_SCHEMA = "elastic/synonyms";
export const POST_SYNONYMS = "[post]elastic/synonyms";
export const PULL_SYNONYMS = "[get]elastic/synonyms";
export const PULL_SYNONYM = "[get]elastic/synonym";
export const PUT_SYNONYM = "[put]elastic/synonym";
export const DELETE_SYNONYM = "[delete]elastic/synonym";

export const SYNONYMS_LIST = "synonyms_list";
export const SYNONYMS_MODEL = "synonyms_model";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  state: "",
  synonyms_list: [],
  model: {
    term: "",
    synonym_type_id: "1",
    id: 0,
    synonyms: [
      {
        name: ""
      }
    ]
  },
  headers: []
};
const getters = {
  modelSynonym(state) {
    return state.model;
  }
};
const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_SYNONYMS](context) {
    state.loading = true;
    return SecureApiService.get("synonyms")
      .then(({ data }) => {
        context.commit(SYNONYMS_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [PULL_SYNONYM](context, id) {
    state.loading = true;
    return SecureApiService.get("synonym", id)
      .then(({ data }) => {
        context.commit(SYNONYMS_MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [DELETE_SYNONYM](context, id) {
    state.loading = true;
    return SecureApiService.delete("synonym", id)
      .then(({ data }) => {
        context.commit(SYNONYMS_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [PUT_SYNONYM](context, credentials) {
    state.loading = true;
    return SecureApiService.update("synonym", credentials.id, credentials.data)
      .then(({ data }) => {
        context.commit(SYNONYMS_MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [POST_SYNONYMS](context, credentials) {
    return new Promise(resolve => {
      SecureApiService.post("synonyms", credentials)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_ERROR, data.errors);
            return;
          }
          context.commit(SET_INFO, data.msg);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [SYNONYMS_LIST](state, data) {
    state.loading = false;
    state.synonyms_list = data;
  },
  [SYNONYMS_MODEL](state, data) {
    state.state = "edit";
    state.loading = false;
    state.model = data;

    let buffer = [];
    data.synonyms.forEach(function(item) {
      buffer.push({
        name: item
      });
    });

    state.model = {
      term: data.term,
      synonym_type_id: data.synonym_type_id,
      id: data.id,
      synonyms: buffer
    };
  },
  [REFRESH_ACTION](state) {
    state.state = "";
    state.model = {
      term: "",
      synonym_type_id: "1",
      id: 0,
      synonyms: [
        {
          name: ""
        }
      ]
    };
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
