import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

export const COMPARE_RESULTS_MODULE = "compare_results";
export const COMPARE_RESULTS_ANALYZE_MODULE = "analyze";
export const COMPARE_RESPONSE = "response";
export const ANALYZE_RESPONSE = "analyze_response";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  data: [],
  analyze: []
};
const getters = {};

const actions = {
  [COMPARE_RESULTS_MODULE](context, data) {
    return ApiService.get(
      "/compare-results",
      "?c=" + data.c + "&q=" + data.q + "&a=" + data.a + "&b=" + data.b
    ).then(({ data }) => {
      if (data.err === 0) {
        context.commit(COMPARE_RESPONSE, data);
      } else {
        context.commit(SET_MESSAGE, {
          err: 1,
          msg: "Internal error, please try again a few minutes later."
        });
      }
    });
  },
  [COMPARE_RESULTS_ANALYZE_MODULE](context, data) {
    return ApiService.get(
      "/analyze",
      "?c=" + data.c + "&term=" + data.q + "&schema=" + data.s
    ).then(({ data }) => {
      if (data.err === 0) {
        context.commit(ANALYZE_RESPONSE, data);
        context.commit(SET_ERROR, []);
      } else {
        context.commit(SET_ERROR, [data.msg]);
      }
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [COMPARE_RESPONSE](state, data) {
    state.data = data.data;
  },
  [ANALYZE_RESPONSE](state, data) {
    state.analyze = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
