const ID_TOKEN_KEY = "sales_channel_id";

export const getProject = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const setProject = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export default { getProject, setProject };
