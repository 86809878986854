import ApiService from "@/core/services/api.service";
import SCService from "@/core/services/sales_channel.service";
import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";
import { COLLECTION_RELOAD_DATA } from "@/core/services/store/collections.module";

// action types
export const PULL_SALES_CHANNELS = "pullSalesChannels";
export const PULL_DEFAULT_SALES_CHANNEL = "pullDefaultSalesChannel";
export const CHANGE_SALES_CHANNEL = "changeSalesChannels";
export const RELOAD_SALES_CHANNEL = "reloadSalesChannels";

export const POST_SALES_CHANNEL = "[post]sales_channel";
export const PUT_SALES_CHANNEL = "[put]sales_channel";
export const DELETE_SALES_CHANNEL = "[delete]sales_channel";
export const PULL_SALES_CHANNEL = "[get]sales_channel";

export const PULL_CREDITALS = "[get]creditals";
export const PULL_MESSAGES = "[get]messages";

export const SALES_CHANNEL_MODEL = "model";
export const SALES_CHANNEL_CREDITALS = "creditals";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

// mutation types
export const SET_SALES_CHANNELS = "setSalesChannels";
export const SET_CURRENT_SALES_CHANNEL = "setCurrentSalesChannel";

const state = {
  errors: [],
  message: {
    code: "",
    text: "",
    color: "info",
    show: false
  },
  disabled: false,
  loading: false,
  current_project: {
    id: 0,
    name: "",
    token: "",
    client_secret: "",
    source: "",
    shoptet_live: false
  },
  creditals: {
    client_id: "",
    client_secret: ""
  },
  model: {
    id: 0,
    url: "",
    name: "",
    active: true
  },
  projects: [],
  project_users: [],
  languages: [],
  messages: [],
  isSalesChannel: SCService.getProject()
};

const getters = {
  currentProject(state) {
    return state.current_project;
  },
  currentProjects(state) {
    return state.projects;
  }
};

const actions = {
  [PULL_SALES_CHANNELS](context) {
    return ApiService.get("sales_channels").then(({ data }) => {
      context.commit(SET_SALES_CHANNELS, data);
    });
  },
  [PULL_MESSAGES]() {
    return ApiService.get("messages").then(({ data }) => {
      state.messages = data.data;
    });
  },
  [PULL_DEFAULT_SALES_CHANNEL](context) {
    return ApiService.get("sales_channel/default").then(({ data }) => {
      context.commit(SET_SALES_CHANNELS, data);
    });
  },
  [CHANGE_SALES_CHANNEL](context, sales_channel_id) {
    return new Promise(resolve => {
      ApiService.get("sales_channel", sales_channel_id).then(({ data }) => {
        context.commit(COLLECTION_RELOAD_DATA, data.collections);
        context.commit(SET_CURRENT_SALES_CHANNEL, data.data);
        resolve(data);
      });
    });
  },
  [POST_SALES_CHANNEL](context, credentials) {
    state.loading = true;
    return new Promise(resolve => {
      SecureApiService.post("sales_channels", credentials)
        .then(({ data }) => {
          state.loading = false;
          if (parseInt(data.err) > 0) {
            context.commit(SET_INFO, data);
            return;
          }

          context.commit(SET_MESSAGE, data);
          context.commit(SALES_CHANNEL_MODEL, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          state.loading = false;
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [PUT_SALES_CHANNEL](context, credentials) {
    return new Promise(resolve => {
      SecureApiService.update(
        "sales_channels",
        credentials.id,
        credentials.data
      )
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          context.commit(SALES_CHANNEL_MODEL, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [RELOAD_SALES_CHANNEL](context) {
    return new Promise(resolve => {
      ApiService.get("current_sales_channel").then(({ data }) => {
        context.commit(COLLECTION_RELOAD_DATA, data.collections);
        context.commit(SET_CURRENT_SALES_CHANNEL, data.data);
        resolve(data);
      });
    });
  },
  [DELETE_SALES_CHANNEL](context, id) {
    return new Promise(resolve => {
      SecureApiService.delete("sales_channels", id)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [PULL_SALES_CHANNEL](context) {
    return SecureApiService.get("sales_channel")
        .then(({ data }) => {
          context.commit(SALES_CHANNEL_MODEL, data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
  },
  [PULL_CREDITALS](context) {
    return SecureApiService.get("/auth/authorize")
        .then(({ data }) => {
          context.commit(SALES_CHANNEL_CREDITALS, data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
  }
};

const mutations = {
  [SET_INFO](state, data) {
    if (data) {
      state.message.text = data.msg;
      state.message.code = data.err;
      state.message.color = "danger";
      state.message.show = true;
      state.hasMessage = true;
    }
  },
  [SET_SALES_CHANNELS](state, data) {
    state.projects = data.data;
    state.languages = data.languages;
  },
  [SET_CURRENT_SALES_CHANNEL](state, sales_channel) {
    state.current_project = sales_channel;
    SCService.setProject(sales_channel.token);
  },
  [SALES_CHANNEL_MODEL](state, data) {
    state.model = data.data;
    state.project_users = data.project_users;
  },
  [SALES_CHANNEL_CREDITALS](state, data) {
    state.creditals = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
