// action types
import SecureApiService from "../secureapi.service";

export const CHARS_FILTER_LOAD = "[pull]elastic/chars_filter";
export const CHAR_FILTER_ADDNEW = "[post]elastic/char_filter";
export const CHAR_FILTER_DELETE = "[delete]elastic/char_filter";

export const CHARS_FILTER_LIST = "char_list";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  state: "",
  chars_list: [],
  headers: []
};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [CHARS_FILTER_LOAD](context) {
    state.loading = true;
    return SecureApiService.get("chars-filter")
      .then(({ data }) => {
        context.commit(CHARS_FILTER_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [CHAR_FILTER_DELETE](context, id) {
    state.loading = true;
    return SecureApiService.delete("char-filter", id)
      .then(({ data }) => {
        context.commit(CHARS_FILTER_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [CHAR_FILTER_ADDNEW](context, credentials) {
    return new Promise(resolve => {
      SecureApiService.post("char-filter", credentials)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_ERROR, data.errors);
            return;
          }
          context.commit(SET_INFO, data.msg);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [CHARS_FILTER_LIST](state, data) {
    state.loading = false;
    state.chars_list = data;
  },
  [REFRESH_ACTION](state) {
    state.state = "";
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations
};
