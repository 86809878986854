// action types
import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

export const PULL_SCHEMAS = "elastic/schema";
export const PULL_SCHEMA = "get/schema";
export const PULL_SCHEMA_TYPES = "elastic/schema/types";
export const POST_SCHEMA = "[post]elastic/schema";
export const PUT_SCHEMA = "[put]elastic/schema";

export const SET_SCHEMA_AS_UNIQUE = "[put]elastic/schema/unique";
export const SET_SCHEMA_AS_FRONT = "[put]elastic/schema/front";

export const SCHEMAS_DATA = "schemas_data";
export const SCHEMA_DATA = "schema_data";
export const SCHEMA_TYPES_DATA_ACTION = "schema_dataAction";

export const CLOSE_DIALOG = "closeDialog";
export const CLOSE_DIALOG_ACTION = "closeDialogAction";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  schema_data: [],
  showDialog: false,
  headers: [],
  types: [],
  model: {
    id: 0,
    name: "",
    unique_field: false,
    one_key: false,
    archive: false,
    front_name: false,
    autocomplete: false,
    custom_data: false,
    type_id: {
      value: "Text",
      key: "keyword"
    },
    temp_type_id: {
      value: "",
      key: ""
    }
  }
};

const getters = {
  modelData(state) {
    return state.model;
  }
};
const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [CLOSE_DIALOG](context) {
    context.commit(CLOSE_DIALOG_ACTION);
  },
  [PULL_SCHEMAS](context, id) {
    state.loading = true;
    return SecureApiService.get("collection/schema", id)
      .then(({ data }) => {
        state.loading = false;
        context.commit(SCHEMAS_DATA, data.data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: "Internal error, please try again a few minutes later."
          });
        }
      });
  },
  [PULL_SCHEMA](context, id) {
    state.loading = true;
    return SecureApiService.get("schema", id)
      .then(({ data }) => {
        state.loading = false;
        context.commit(SCHEMA_DATA, data.data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: "Internal error, please try again a few minutes later."
          });
        }
      });
  },
  [PULL_SCHEMA_TYPES](context) {
    state.loading = true;
    return SecureApiService.get("elastic/schema-types")
      .then(({ data }) => {
        context.commit(SCHEMA_TYPES_DATA_ACTION, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_MESSAGE, response.data);
        } else {
          context.commit(SET_MESSAGE, {
            err: 1,
            msg: "Internal error, please try again a few minutes later."
          });
        }
      });
  },
  [POST_SCHEMA](context, credentials) {
    return new Promise(resolve => {
      SecureApiService.post("elastic/schema", credentials)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          resolve(true);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [PUT_SCHEMA](context, credentials) {
    return new Promise(resolve => {
      SecureApiService.update("schema", credentials.id, credentials.data)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          resolve(true);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [SET_SCHEMA_AS_UNIQUE](context, id) {
    return new Promise(resolve => {
      SecureApiService.update("schema/unique", id)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          resolve(true);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [SET_SCHEMA_AS_FRONT](context, id) {
    return new Promise(resolve => {
      SecureApiService.update("schema/front_name", id)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          resolve(true);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  }
};

const mutations = {
  [CLOSE_DIALOG_ACTION](state) {
    state.model = {
      id: 0,
      name: "",
      unique: false,
      one_key: false,
      front_name: false,
      archive: false,
      autocomplete: false,
      custom_data: false,
      type_id: {
        value: "Text",
        key: "keyword"
      },
      temp_type_id: {
        value: "",
        key: ""
      }
    };
    state.showDialog = false;
  },
  [SCHEMA_TYPES_DATA_ACTION](state, data) {
    state.types = data;
  },
  [SCHEMAS_DATA](state, data) {
    state.loading = false;
    state.schema_data = data;
  },
  [SCHEMA_DATA](state, data) {
    state.showDialog = true;
    state.loading = false;
    state.model = {
      id: data.id,
      name: data.name,
      unique_field: data.unique_field === "1",
      one_key: data.one_key === "1",
      archive: data.archive,
      front_name: data.front_name === "1",
      autocomplete: data.autocomplete,
      custom_data: data.custom_data === "1",
      type_id: data.type,
      temp_type_id: data.temp_type
    };
  },
  [REFRESH_ACTION](state) {
    state.documents = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
