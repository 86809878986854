// action types
import SecureApiService from "../secureapi.service";

export const DELETE_STOPWORD = "[delete]elastic/stopword";
export const ADD_STOPWORD = "[put]elastic/stopword";
export const ADD_SET_STOPWORDS = "[put]elastic/stopword_set";
export const PULL_STOPWORDS = "[pull]elastic/stopwords";

export const STOPWORDS_LIST = "list/stopwords";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

class predefinedSet {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  stopwords: [],
  predefined_sets: [
    new predefinedSet("Bulharsko", "bulgarian"),
    new predefinedSet("Czech", "czech"),
    new predefinedSet("Danish", "danish"),
    new predefinedSet("Dutch", "dutch"),
    new predefinedSet("English", "english"),
    new predefinedSet("French", "french"),
    new predefinedSet("German", "german"),
    new predefinedSet("Hungarian", "hungarian"),
    new predefinedSet("Italian", "italian"),
    new predefinedSet("Spanish", "spanish")
  ],
  term: ""
};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_STOPWORDS](context) {
    state.loading = true;
    return SecureApiService.get("stopwords")
      .then(({ data }) => {
        context.commit(STOPWORDS_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [DELETE_STOPWORD](context, term) {
    state.loading = true;
    return SecureApiService.delete("stopwords", term)
      .then(({ data }) => {
        context.commit(STOPWORDS_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [ADD_STOPWORD](context, data) {
    state.loading = true;
    return SecureApiService.post("stopwords", data)
      .then(({ data }) => {
        context.commit(STOPWORDS_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [ADD_SET_STOPWORDS](context, data) {
    state.loading = true;
    return SecureApiService.put("stopwords/set/" + data)
      .then(({ data }) => {
        context.commit(STOPWORDS_LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [STOPWORDS_LIST](state, data) {
    state.loading = false;
    state.stopwords = data;
  },
  [REFRESH_ACTION](state) {
    state.stopwords = [];
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
    state.term = "";
  }
};

export default {
  state,
  actions,
  mutations
};
