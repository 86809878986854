import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

// mutation types
export const GET_AUTOCOMPLETE = "[get]autocomplete";
export const PUT_AUTOCOMPLETE = "[put]autocomplete";

export const GET_SEARCH = "[get]search";
export const GET_CATALOG_INFOS = "[get]catalogInfos";
export const PUT_SEARCH = "[put]search";

export const AUTOCOMPLETE = "autocomplete";
export const SEARCH = "search";

export const PULL_LAYOUT_COLUMNS = "[pull]layoutColumns";
export const GET_LIST_COLLECTIONS = "[get]collections";
export const LAYOUT_COLUMNS = "layout_columns";
export const COLLECTIONS = "collections";
export const CATALOG_INFOS = "catalog_infos";

const state = {
  autocomplete: [],
  search: [],
  catalog_infos: [],
  layout_columns: [],
  collections: []
};

class Collection {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

const getters = {};

const actions = {
  [GET_AUTOCOMPLETE](context, data) {
    return ApiService.get("/result-settings/autocomplete", data).then(
      ({ data }) => {
        context.commit(AUTOCOMPLETE, data.data);
      }
    );
  },
  [GET_LIST_COLLECTIONS](context) {
    return ApiService.get("/collections").then(({ data }) => {
      context.commit(COLLECTIONS, data.data);
    });
  },
  [GET_CATALOG_INFOS](context) {
    return ApiService.get("/result-settings/catalog-info/all").then(({ data }) => {
      context.commit(CATALOG_INFOS, data.data);
    });
  },
  [PUT_AUTOCOMPLETE](context, data) {
    return ApiService.put("/result-settings/autocomplete", data).then(
      ({ data }) => {
        context.commit(AUTOCOMPLETE, data.data);
        context.commit(SET_MESSAGE, data);
      }
    );
  },
  [GET_SEARCH](context, data) {
    return ApiService.get("/result-settings/search", data).then(({ data }) => {
      context.commit(SEARCH, data.data);
    });
  },
  [PUT_SEARCH](context, data) {
    return ApiService.put("/result-settings/search", data).then(({ data }) => {
      context.commit(SEARCH, data.data);
      context.commit(SET_MESSAGE, data);
    });
  },
  [PULL_LAYOUT_COLUMNS](context) {
    return ApiService.get("/layout-columns/").then(({ data }) => {
      context.commit(LAYOUT_COLUMNS, data.data);
    });
  }
};

const mutations = {
  [AUTOCOMPLETE](state, data) {
    state.autocomplete = data;
  },
  [SEARCH](state, data) {
    state.search = data;
  },
  [CATALOG_INFOS](state, data) {
    state.catalog_infos = data;
  },
  [LAYOUT_COLUMNS](state, data) {
    state.layout_columns = data;
  },
  [COLLECTIONS](state, data) {
    state.collections = [];
    state.collections.push(new Collection(null, "without"));

    data.forEach(function(field) {
      state.collections.push(new Collection(field.id, field.name));
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
