import SecureApiService from "../secureapi.service";

export const ENDPOINT_LIST = "check_items/endpoint/list";
export const ENDPOINT_POST = "check_items/endpoint/post";
export const ENDPOINT_GET = "check_items/endpoint/get";
export const ENDPOINT_PUT = "check_items/endpoint/put";
export const ENDPOINT_DELETE = "check_items/endpoint/delete";

export const LIST = "check_items/list";
export const MODEL = "check_items/model";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  dialog: false,
  list: [],
  model: {
    elastic_item_id: null,
    alert_above: 0,
    term: ""
  },
  headers: []
};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [ENDPOINT_LIST](context) {
    state.loading = true;
    return SecureApiService.get("checkitems")
      .then(({ data }) => {
        context.commit(LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [ENDPOINT_GET](context, id) {
    state.loading = true;
    return SecureApiService.get("checkitem", id)
      .then(({ data }) => {
        context.commit(MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [ENDPOINT_DELETE](context, id) {
    state.loading = true;
    return SecureApiService.delete("checkitem", id)
      .then(({ data }) => {
        context.commit(LIST, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [ENDPOINT_PUT](context, credentials) {
    state.loading = true;
    return SecureApiService.update(
      "checkitem",
      credentials.id,
      credentials.data
    )
      .then(({ data }) => {
        context.commit(MODEL, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [ENDPOINT_POST](context, credentials) {
    return new Promise(resolve => {
      SecureApiService.post("checkitem", credentials)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_ERROR, data.errors);
            return;
          }
          context.commit(SET_INFO, data.msg);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERROR, response.data.errors);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [LIST](state, data) {
    state.loading = false;
    state.list = data;
  },
  [MODEL](state, data) {
    state.dialog = true;
    state.loading = false;
    state.model = data;
  },
  [REFRESH_ACTION](state) {
    state.dialog = false;
    state.model = {
      elastic_item_id: null,
      alert_above: 0,
      term: ""
    };
    state.errors = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations
};
