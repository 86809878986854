import SecureApiService from "../secureapi.service";
import { SET_MESSAGE } from "@/core/services/store/message.module";

export const LOAD_TOTAL_QUERIES = "[GET]widget/dashboard/total_queries";
export const LOAD_TOTAL_QUERIES_WITHOUT_QUERIES =
  "[GET]widget/dashboard/total_queries_without_results";

export const SET_DATA_TOTAL_QUERIES = "setdataTotalQueries";
export const SET_DATA_TOTAL_QUERIES_WITHOUT_RESULTS =
  "setdataTotalQueriesWithoutResults";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";

const state = {
  total_queries: [
    {
      queries: 0,
      unique_users: null,
      timeline: {
        data: [],
        x_tooltip: "dd MMM"
      }
    }
  ],
  total_queries_without_results: [
    {
      queries: 0,
      unique_users: null,
      timeline: {
        data: [],
        x_tooltip: "dd MMM"
      }
    }
  ],
  loading: {
    total_queries: false,
    total_queries_without_results: false
  }
};
const getters = {};

const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [LOAD_TOTAL_QUERIES_WITHOUT_QUERIES](context, credentials) {
    return new Promise(resolve => {
      state.loading.total_queries_without_results = true;

      SecureApiService.post(
        "widget/dashboard/total_queries_without_results",
        credentials
      )
        .then(({ data }) => {
          state.loading.total_queries_without_results = false;
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          context.commit(SET_DATA_TOTAL_QUERIES_WITHOUT_RESULTS, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          state.loading.total_queries_without_results = false;
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  },
  [LOAD_TOTAL_QUERIES](context, credentials) {
    return new Promise(resolve => {
      state.loading.total_queries = true;

      SecureApiService.post("widget/dashboard/total_queries", credentials)
        .then(({ data }) => {
          state.loading.total_queries = false;
          if (data.err === 1) {
            context.commit(SET_MESSAGE, data);
            return;
          }
          context.commit(SET_MESSAGE, data);
          context.commit(SET_DATA_TOTAL_QUERIES, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          state.loading.total_queries = false;
          if (response.data.errors) {
            context.commit(SET_MESSAGE, response.data);
          } else {
            context.commit(SET_MESSAGE, {
              err: 1,
              msg: "Internal error, please try again a few minutes later."
            });
          }
        });
    });
  }
};

const mutations = {
  [SET_DATA_TOTAL_QUERIES](state, data) {
    state.loading.total_queries = false;
    state.total_queries = data.data;
  },
  [SET_DATA_TOTAL_QUERIES_WITHOUT_RESULTS](state, data) {
    state.loading.total_queries_without_results = false;
    state.total_queries_without_results = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
