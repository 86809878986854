// action types
import SecureApiService from "../secureapi.service";

export const POST_CURATION = "[post]curation";
export const PULL_CURATIONS = "[get]curations";
export const PULL_CURATION = "[get]curation";
export const PUT_CURATION = "[put]curation";
export const DELETE_CURATION = "[delete]curation";

export const CURATION_SEARCH = "[search]curation";
export const CURATION_SEARCH_ITEMS = "search_items";

export const CURATIONS = "curations";
export const CURATION = "curation";

export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";

export const REFRESH = "refresh";
export const REFRESH_ACTION = "refreshAction";
export const REFRESH_EDIT_CURATION = "refreshEditCuration";

const state = {
  errors: [],
  hasMessage: false,
  loading: false,
  message: "",
  curations: [],
  filteredItems: [],
  curation: {
    id: "new",
    name: "",
    queries: [""],
    promoted: [],
    hidden: [],
    queriesList: [],
    promotedList: [],
    hiddenList: []
  }
};
const getters = {
  getCurration(state) {
    return state.curation;
  }
};
const actions = {
  [REFRESH](context) {
    context.commit(REFRESH_ACTION);
  },
  [PULL_CURATIONS](context, collection) {
    state.loading = true;
    return SecureApiService.get("curations", collection.id)
      .then(({ data }) => {
        state.loading = false;
        context.commit(CURATIONS, data.data);
      })
      .catch(({ response }) => {
        state.loading = false;
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [PULL_CURATION](context, id) {
    state.loading = true;
    return SecureApiService.get("curation", id)
      .then(({ data }) => {
        context.commit(CURATION, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [DELETE_CURATION](context, id) {
    state.loading = true;
    return SecureApiService.delete("curation", id)
      .then(({ data }) => {
        context.commit(CURATIONS, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [CURATION_SEARCH](context, data) {
    return SecureApiService.get("curation-organic/?" + data).then(
      ({ data }) => {
        context.commit(CURATION_SEARCH_ITEMS, data.data);
      }
    );
  },
  [PUT_CURATION](context, data) {
    state.loading = true;
    return SecureApiService.update("curation", data.id, data.data)
      .then(({ data }) => {
        context.commit(CURATION, data.data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          context.commit(SET_ERROR, response.data.errors);
        } else {
          context.commit(SET_ERROR, [
            "Internal error, please try again a few minutes later."
          ]);
        }
      });
  },
  [POST_CURATION](context, data) {
    return new Promise(resolve => {
      SecureApiService.post("curation", data)
        .then(({ data }) => {
          if (data.err === 1) {
            context.commit(SET_ERROR, [data.msg]);
            return;
          }
          context.commit(SET_INFO, data.msg);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response) {
            context.commit(SET_ERROR, response);
          } else {
            context.commit(SET_ERROR, [
              "Internal error, please try again a few minutes later."
            ]);
          }
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.loading = false;
    state.errors = error;
  },
  [SET_INFO](state, message) {
    if (message) {
      state.message = message;
      state.hasMessage = true;
    }
  },
  [CURATION_SEARCH_ITEMS](state, data) {
    state.filteredItems = data;
  },
  [CURATIONS](state, data) {
    state.loading = false;
    state.curations = [];

    data.forEach(function(row) {
      let promoted = [];
      row.promoted.forEach(function(item) {
        promoted.push(item.id);
      });
      let hidden = [];
      row.hidden.forEach(function(item) {
        hidden.push(item.id);
      });
      let queries = [];
      row.queries.forEach(function(item) {
        queries.push(item.query);
      });

      row.queriesList = queries;
      row.promotedList = promoted;
      row.hiddenList = hidden;

      state.curations.push(row);
    });
  },
  [CURATION](state, data) {
    state.loading = false;
    state.curation = data;

    let promoted = [];
    data.promoted.forEach(function(item) {
      promoted.push(item.id);
    });
    let hidden = [];
    data.hidden.forEach(function(item) {
      hidden.push(item.id);
    });
    let queries = [];
    data.queries.forEach(function(item) {
      queries.push(item.query);
    });

    state.curation.queriesList = queries;
    state.curation.promotedList = promoted;
    state.curation.hiddenList = hidden;
  },
  [REFRESH_ACTION](state) {
    state.curation = {
      id: "new",
      name: "",
      queries: [""],
      promoted: [],
      hidden: [],
      queriesList: [],
      promotedList: [],
      hiddenList: []
    };
    state.errors = [];
    state.filteredItems = [];
    state.hasMessage = false;
    state.loading = false;
    state.message = "";
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
